import React from 'react';

// Root Include

//Special
const UpdateStandings = React.lazy(() => import('./pages/Pages/Special/update-standings'));

const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
const TermsOfService = React.lazy(() => import("./pages/Pages/Special/TermsOfService"));
const Privacy = React.lazy(() => import("./pages/Pages/Special/Privacy"));

const PageMaintenance = React.lazy(() => import('./pages/Pages/Special/PageMaintenance'));
const DashboardControl = React.lazy(() =>
  import("./pages/Pages/Special/DashboardControl")
);

//User Pages
const PageLogin = React.lazy(() => import('./pages/Pages/User/PageLogin'));
const PageCoverLogin = React.lazy(() => import('./pages/Pages/User/PageCoverLogin'));
const PageCoverRePassword = React.lazy(() => import('./pages/Pages/User/PageCoverRePassword'));
const PageCoverSignup = React.lazy(() => import('./pages/Pages/User/JoinWaitlist'));
const PaymentSuccess = React.lazy(() => import('./pages/Pages/User/payment-success'));
const ContactUs = React.lazy(() => import('./pages/Pages/User/ContactUs'));
const ClaimPrize = React.lazy(() => import("./pages/Pages/User/ClaimPrize"));
const PageSignup = React.lazy(() => import('./pages/Pages/User/PageSignup'));
const PageForgotPassword = React.lazy(() => import("./pages/Pages/User/PageForgotPassword"));
const PageSignout = React.lazy(() => import("./pages/Pages/User/PageSignout"));

// Import all components
const ForArtists = React.lazy(() => import('./pages/ForArtists/index'));
const ForCreators = React.lazy(() => import("./pages/ForCreators/index"));

//Docs
const ChangeLog = React.lazy(() => import('./pages/Docs/ChangeLog'));
const Components = React.lazy(() => import('./pages/Docs/Components'));
const Documentation = React.lazy(() => import('./pages/Docs/Documentation'));
const Widget = React.lazy(() => import('./pages/Docs/widget'));

const CopyLink = React.lazy(() => import('./pages/Pages/CopyLink'));
const FAQs = React.lazy(() => import("./pages/Pages/FAQs"));
const HowItWorks = React.lazy(() => import("./pages/Pages/HowItWorks"));
const Referrals = React.lazy(() => import("./pages/Pages/Referrals"));
const Active = React.lazy(() => import("./pages/Pages/Active"));
const Past = React.lazy(() => import("./pages/Pages/Past"));

//Account
const CompetitionDetail = React.lazy(() => import('./pages/Pages/Account/competition-detail'));
const JoinWaitlist = React.lazy(() => import('./pages/Pages/User/JoinWaitlist'));

//Career
const LandingPage = React.lazy(() => import('./pages/Pages/LandingPage/LandingPage'));
const Profile = React.lazy(() => import('./pages/Pages/LandingPage/Profile'));


const routes = [
  //routes without Layout

  //Contct withour layout
  { path: "/artists", component: ForArtists },
  { path: "/creators", component: ForCreators },

  //Page Profile
  { path: "/c/", component: CompetitionDetail },
  { path: "/start", component: JoinWaitlist, isWithoutLayout: true },
  // { path: "/crypto", component: Cryptocurrency },
  //Page Job
  { path: "/user-landing", component: LandingPage },
  { path: "/dashboard", component: DashboardControl },
  { path: "/link-to-post", component: CopyLink },
  { path: "/faqs", component: FAQs },
  { path: "/how-it-works", component: HowItWorks },
  { path: "/active", component: Active },
  { path: "/past", component: Past },
  { path: "/joinwaitlist", component: JoinWaitlist, isWithoutLayout: true },
  {
    path: "/payment-success",
    component: PaymentSuccess,
    isWithoutLayout: true,
  },
  { path: "/contactus", component: ContactUs, isWithoutLayout: true },
  { path: "/claimprize", component: ClaimPrize },

  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignup },
  // {
  //   path: "/update-standings",
  //   component: UpdateStandings,
  //   isWithoutLayout: true,
  // },
  { path: "/forgotpassword", component: PageForgotPassword },
  { path: "/profile", component: Profile },
  { path: "/terms", component: TermsOfService },
  { path: "/privacy", component: Privacy },
  { path: "/signout", component: PageSignout, isWithoutLayout: true },
  // {
  //   path: "/page-comingsoon",
  //   component: PageComingSoon,
  //   isWithoutLayout: true,
  // },

  //Index Main

  //Root
  // { path: "/", component: PageJob},
  {
    path: "/",
    component: LandingPage,
  },
];

export default routes;