import React, { Component } from 'react';
import { Link } from "react-router-dom";

//Import Switcher CSS

class ThemeSwitcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSwitchToggle : false
        }
        this.toggleSwitcher = this.toggleSwitcher.bind(this);
    }
     
    toggleSwitcher = () => {
         this.setState({ isSwitchToggle : !this.state.isSwitchToggle });
        // console.log(this.state.isSwitchToggle)
    }
    render() {
        
        return (
            <React.Fragment>
                
            </React.Fragment>
        );
    }
}

export default ThemeSwitcher;